import { Builder } from '@builder.io/react';
import { accordionConfig } from '@builder.io/widgets/dist/lib/components/Accordion.config';
import dynamic from 'next/dynamic';

Builder.registerComponent(
  dynamic(() =>
    import('@builder.io/widgets/dist/lib/components/Accordion').then(mod => mod.AccordionComponent)
  ),
  accordionConfig
);

Builder.registerComponent(
  dynamic(() => import('./components/Alert')),
  {
    name: 'Alert',
    inputs: [{ name: 'title', type: 'text' }],
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/components.png'
  }
);

Builder.registerComponent(
  dynamic(() => import('./components/formikFormInputs/Button')),
  {
    name: 'Button',
    inputs: [
      { name: 'content', type: 'string' },
      { name: 'label', type: 'string' },
      { name: 'loading', type: 'boolean' },
      { name: 'primary', type: 'boolean' },
    ],
  //  inputs: [{ content: 'string', label: string, loading: boolean; }],
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/components.png'
  }
);

Builder.registerComponent(
  dynamic(() => import('./components/Table/GridView')),
  {
    name: 'GridView',
    inputs: [{ name: 'title', type: 'text' }],
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/table.png'
  }
);

Builder.registerComponent(
  dynamic(() => import('./components/Loader')),
  {
    name: 'Loader',
    inputs: [{ name: 'title', type: 'text' }],
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/refresh-dot.png'
  }
);

Builder.registerComponent(
  dynamic(() => import('./components/Table/Pagination')),
  {
    name: 'Pagination',
    inputs: [{ name: 'title', type: 'text' }],
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/components.png'
  }
);

Builder.registerComponent(
  dynamic(() => import('./components/formikFormInputs/TextField')),
  {
    name: 'TextField',
    inputs: [{ name: 'title', type: 'text' }],
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/components.png'
  }
);


Builder.register('insertMenu', {
  name: 'Nick Components',
  items: [
    { name: 'Alert' },
    { name: 'Button' },
    { name: 'GridView' },
    { name: 'Loader' },
    { name: 'Pagination' },
    { name: 'TextField' },
  ],
})
