import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import '@builder.io/widgets';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import Footer from '../components/Footer/Footer';
import Loader from "../components/Loader";
import NotFound from './404';
import { BUILDER_IO_API_KEY } from '../server/constants';
import '../builder.config';

const Header = dynamic(() => import('../components/Header/Header'), {ssr: false});

/*
  Initialize the Builder SDK with your organization's API Key
  The API Key can be found on: https://builder.io/account/settings
*/
builder.init(BUILDER_IO_API_KEY);

// @ts-ignore
export async function getStaticProps({params}: any) {
  /*
    Fetch the first page from Builder that matches the current URL.
    The `userAttributes` field is used for targeting content,
    learn more here: https://www.builder.io/c/docs/targeting-with-builder
  */
  const page = await builder
    .get('page', {
      userAttributes: {
        urlPath: '/' + (params?.page?.join('/') || ''),
      },
    })
    .toPromise();

  return {
    props: {
      page: page || null,
    },
    revalidate: 5,
  };
}

// @ts-ignore
export async function getStaticPaths() {
  /*
    Fetch all published pages for the current model.
    Using the `fields` option will limit the size of the response
    and only return the `data.url` field from the matching pages.
  */
  const pages = await builder.getAll('page', {
    fields: 'data.url', // only request the `data.url` field
    options: {noTargeting: true},
    limit: 0,
  });

  return {
    paths: pages.map(page => `${page.data?.url}`),
    fallback: true,
  };
}


const Page = ({page}: any): JSX.Element => {
  const router = useRouter();
  /*
    This flag indicates if you are viewing the page in the Builder editor.
  */
  const isPreviewing = useIsPreviewing();

  if (router.isFallback) {
    debugger;
    return <Loader/>;
  }

  /*
    Add your error page here. This will happen if there are no matching
    content entries published in Builder.
  */
  if (!page && !isPreviewing) {
    debugger;
    return <NotFound/>;
  }

  return (
    <>
      <Head>
        {/* Add any relevant SEO metadata or open graph tags here */}
        <title>{page?.data.title}</title>
        <meta name="description" content={page?.data.descripton}/>
      </Head>

      <Header/>
      {/* Render the Builder page */}
      <BuilderComponent model="page" content={page}/>
      <Footer/>
    </>
  );
};


export default Page;

